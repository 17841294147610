/* comfortaa-300 - latin */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/comfortaa/comfortaa-v29-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/comfortaa/comfortaa-v29-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/comfortaa/comfortaa-v29-latin-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/comfortaa/comfortaa-v29-latin-300.woff")
      format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/comfortaa/comfortaa-v29-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/comfortaa/comfortaa-v29-latin-300.svg#Comfortaa")
      format("svg"); /* Legacy iOS */
}
/* comfortaa-regular - latin */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/comfortaa/comfortaa-v29-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/comfortaa/comfortaa-v29-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./assets/fonts/comfortaa/comfortaa-v29-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/comfortaa/comfortaa-v29-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/comfortaa/comfortaa-v29-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/comfortaa/comfortaa-v29-latin-regular.svg#Comfortaa")
      format("svg"); /* Legacy iOS */
}
/* comfortaa-700 - latin */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/comfortaa/comfortaa-v29-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/comfortaa/comfortaa-v29-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/comfortaa/comfortaa-v29-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/comfortaa/comfortaa-v29-latin-700.woff")
      format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/comfortaa/comfortaa-v29-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/comfortaa/comfortaa-v29-latin-700.svg#Comfortaa")
      format("svg"); /* Legacy iOS */
}

/* nunito-300 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/nunito/nunito-v14-latin-300.eot"); /* IE9 Compat Modes */
  src: local("Nunito Light"), local("Nunito-Light"),
    url("./assets/fonts/nunito/nunito-v14-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/nunito/nunito-v14-latin-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/nunito/nunito-v14-latin-300.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/nunito/nunito-v14-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/nunito/nunito-v14-latin-300.svg#Nunito") format("svg"); /* Legacy iOS */
}
/* nunito-regular - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/nunito/nunito-v14-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Nunito Regular"), local("Nunito-Regular"),
    url("./assets/fonts/nunito/nunito-v14-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/nunito/nunito-v14-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/nunito/nunito-v14-latin-regular.woff") format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/nunito/nunito-v14-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/nunito/nunito-v14-latin-regular.svg#Nunito")
      format("svg"); /* Legacy iOS */
}
/* nunito-800 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  src: url("./assets/fonts/nunito/nunito-v14-latin-800.eot"); /* IE9 Compat Modes */
  src: local("Nunito ExtraBold"), local("Nunito-ExtraBold"),
    url("./assets/fonts/nunito/nunito-v14-latin-800.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/nunito/nunito-v14-latin-800.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/nunito/nunito-v14-latin-800.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/nunito/nunito-v14-latin-800.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/nunito/nunito-v14-latin-800.svg#Nunito") format("svg"); /* Legacy iOS */
}
